<div id="loader">
  <div id="background-overlay"></div>
  <div id="content-overlay">
    <div id="img-overlay">
      <img id="splash-img" class="splash-img" src="/assets/loader.jpg" />
    </div>
  </div>
  <div class="lds-ripple">
    <div></div>
    <div></div>
  </div>
</div>


<div id="container">
  <div id="app">
    <router-outlet></router-outlet>
  </div>
</div>
