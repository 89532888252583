<div id="header" class="header">Our Family</div>


<div id="grid" style="overflow:hidden">
  <div class="grid-container" id="grid-container">

    <a id="hayden" href="http://www.haydensookchand.com">
      <div class="grid-item">
        <img class="icon" src="assets/hayden.jpg">
        <div id="text" class="name">Hayden</div>
      </div>
    </a>

    <a id="derosha" href="http://www.dnaidu.sookchand.com">
      <div class="grid-item">
        <img class="icon" src="assets/derosha.jpg">
        <div id="text2" class="name">Derosha</div>
      </div>
    </a>



  </div>

</div>
<div id="quote">"For God alone, O my soul, wait in silence,for my hope is from him. - Psalms 62.5"</div>
