import { Component } from '@angular/core';
import { TweenLite, TweenMax, TimelineMax } from 'gsap';
import { Power1, Bounce } from 'gsap/all';
@Component({
  selector: 'app',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: []
})
export class HomeComponent {
  textAnimation: Object;
  data: Object;
  name: String;
  constructor() {}

  ngOnInit(): void {
    var that = this;
    setTimeout(function() {
      that.removeLoadingScreen();
      that.init();
    }, 700);
  }

  init() {
    this.bindToElements();
    this.setUpAnimations();
    this.playAnimations();
  }

  /** ***************************** Bind and Setup ***************************************************/

  bindToElements(this) {
    this.header = document.getElementById('header');
    this.grid = document.getElementById('grid-container');
    this.name = document.getElementById('text');
    this.name2 = document.getElementById('text2');
  }

  setUpAnimations() {
    this.textAnimation = new TimelineMax({ repeat: 0 });
  }

  playAnimations(this) {
    TweenMax.fromTo(this.header, 1, { y: -50 }, { y: 0, ease: Power1.easeOut });
    this.textAnimation.to(this.grid, 1, { scale: 1.4, opacity: 0, y: 0 });
    this.textAnimation.to(this.grid, 2, { scale: 1, opacity: 1, y: 0 });
    TweenMax.fromTo(this.name, 3, { y: 2000 }, { y: 0, ease: Power1.easeOut });
    TweenMax.fromTo(this.name2, 3, { y: 2000 }, { y: 0, ease: Power1.easeOut });
  }

  showLoadingScreen() {
    var loadingBar = document.getElementById('loader');
    loadingBar.style.display = 'block';
  }

  removeLoadingScreen() {
    var loadingBar = document.getElementById('loader');
    loadingBar.style.display = 'none';
  }
}
